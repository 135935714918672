import { SET_VIEW_ALL_CHATS, UPDATE_VIEW_ALL_CHATS } from '../constants/action-types';

export default function viewAllChats(state = [], action) {
  switch (action.type) {
    case SET_VIEW_ALL_CHATS:
      return action.payload;

    case UPDATE_VIEW_ALL_CHATS:
      const { chat, chats, deleteChat } = action.payload;

      let newChats = chats.filter(el => el.id !== chat.id);

      if (!deleteChat) {
        newChats.unshift(chat);
      }

      return newChats;

    default:
      return state;
  }
}
