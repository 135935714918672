import Cookies from 'js-cookie';
import Axios from 'axios';
import moment from 'moment';
import * as workerTimers from 'worker-timers';
import Logout from './Logout';
import Log from './Log';
import { TMP_STREAM_PAUSE } from '../components/Pages/Incoming/constants';
import {
  IOT_ACCESS_KEY,
  IOT_SECRET_KEY,
  IOT_SESSION_TOKEN,
  SESSION_COOKIE_NAME,
  REFRESH_COOKIE_NAME,
  ID_TOKEN_NAME,
  SESSION_NAME,
  REFRESH_ERRORS,
} from '../constants/cookies';

let tabIntervalId;

const api = Cookies.withAttributes({
  sameSite: 'strict',
  secure: false,
});

// clear intervals on browser tab close
window.addEventListener('beforeunload', () => {
  clearIntervals();
});

export const clearCookies = () => {
  Log.color('Clearing cookies!', 'orange');
  Cookies.remove(ID_TOKEN_NAME);
  Cookies.remove(SESSION_NAME);
  Cookies.remove(IOT_ACCESS_KEY);
  Cookies.remove(IOT_SECRET_KEY);
  Cookies.remove(IOT_SESSION_TOKEN);
  Cookies.remove(REFRESH_ERRORS);
  Cookies.remove(TMP_STREAM_PAUSE);
};

export const manageSession = (code) => {
  Log.color('managing session!', code, 'navy');
  const token = Cookies.get(ID_TOKEN_NAME);
  const loggedIn = token && Cookies.get(SESSION_NAME);
  let beginSessionHeartBeat = false;

  // if first login or token refresh
  if (code) {
    Log.color(' I have a code! ', 'navy');

    let now = moment();
    let IDexpiry = now.add(4, 'm').toDate();
    Cookies.set(ID_TOKEN_NAME, code, {
      expires: IDexpiry,
    });
    // create initial active session
    // this is 10 seconds because the first authenticated app load hogs the eventloop for a while
    Cookies.set(SESSION_NAME, 'active', {
      expires: now.add(parseInt(process.env.SESSION_EXPIRE), 's').toDate(),
    });
    // begin managing active session here
    beginSessionHeartBeat = true;
  } else if (loggedIn) {
    Log.color('I am logged in', 'navy');
    beginSessionHeartBeat = true;
  } else {
    Log.color('I need to login', 'navy');
    const { hash, pathname } = window.location;
    localStorage.clear();
    clearCookies();
    localStorage.setItem('returnLocation', `${pathname}${hash ? hash : ''}`);
  }
  return beginSessionHeartBeat;
};

export const keepSessionAlive = () => {
  //Log.color('initiating keep session alive', 'navy');
  if (!tabIntervalId) {
    tabIntervalId = workerTimers.setInterval(() => {
      //Log.trace('Running main interval');

      if (!Cookies.get(SESSION_COOKIE_NAME)) {
        Log.trace('no session cookie, going to set new interval');
        const newSessionId = workerTimers.setInterval(() => {
          //Log.color('keep session alive', 'navy');
          let sessionExpiry = moment()
            .add(parseInt(process.env.SESSION_EXPIRE), 's')
            .toDate();
          Cookies.set(SESSION_NAME, 'active', {
            expires: sessionExpiry,
          });
          Cookies.set(SESSION_COOKIE_NAME, newSessionId);
        }, process.env.SESSION_INTERVAL);
      }
      if (!Cookies.get(REFRESH_COOKIE_NAME)) {
        Log.trace('no refresh cookie, going to set new interval');
        const newRefreshId = workerTimers.setInterval(() => {
          Log.color('keeping token alive', 'navy');
          refreshToken();
        }, process.env.REFRESH_INTERVAL);
        Cookies.set(REFRESH_COOKIE_NAME, newRefreshId);
      }
    }, 2000);
  }
};

export const clearIntervals = () => {
  if (Cookies.get(REFRESH_COOKIE_NAME)) {
    workerTimers.clearInterval(parseInt(Cookies.get(REFRESH_COOKIE_NAME)));
  }
  if (Cookies.get(SESSION_COOKIE_NAME)) {
    workerTimers.clearInterval(parseInt(Cookies.get(SESSION_COOKIE_NAME)));
  }
  if (tabIntervalId) {
    workerTimers.clearInterval(tabIntervalId);
  }
  Cookies.remove(SESSION_COOKIE_NAME);
  Cookies.remove(REFRESH_COOKIE_NAME);
};

export const refreshToken = () => {
  return new Promise((resolve, reject) => {
    let token = Cookies.get('id_token');
    Log.color('inside refresh token', 'orange');
    if (token) {
      Log.color(`Refreshing id_token with new expiry`, 'orange');
      const url = `${process.env.SAML_URL}saml/refreshSession`;

      Axios.post(url, { code: token })
        .then((res) => {
          Log.color('Successfully refreshed cookie', res, 'orange');
          manageSession(res.data.access_code);
          setRefreshErrorCookie(0);
          resolve();
        })
        .catch((err) => {
          const msg = 'Error refreshing session';
          Log.error(msg, err);
          incrementRefreshError();
          if (parseInt(Cookies.get(REFRESH_ERRORS)) > 5) {
            Logout.logout();
            reject();
          }
        });
    } else {
      const msg = 'no token was available to refresh';
      Log.color(msg, 'orange');
      reject(msg);
    }
  });
};

export const setRefreshErrorCookie = (num) => {
  Cookies.set(REFRESH_ERRORS, num);
};

const incrementRefreshError = () => {
  let count = parseInt(Cookies.get(REFRESH_ERRORS)) || 0;
  count += 1;
  setRefreshErrorCookie(count);
};
