/* eslint-disable no-console */
import Axios from 'axios';
import Cookies from 'js-cookie';
import { clearCookies } from './cookie';
import Log from './Log';
import { getUserModel } from '../models';
import Client from './client';

class Logout {
  logout() {
    const { pubTopic, id, login_ip, email } = getUserModel();
    const topic = `user${pubTopic}logOut`;
    const token = Cookies.get('id_token');
    Log.trace('Ending my session.  My code is ', token);
    let payload = { code: token };
    const url = `${process.env.SAML_URL}saml/terminateThisSession`;
    Axios.post(url, payload)
      .then(async (res) => {
        // Log.trace('Terminated this session', res);
        if (res.status === 200) {
          // Log.trace('Ended this session');
          // Log.trace(`Sending logOut!!!! on topic = ${topic}`);
          await Client.publish(topic, {
            action: 'userLogOut',
            id: id,
            ip: login_ip,
          });
          await Client.publish(`sessions/${email.toLowerCase()}`, {
            action: 'logout',
            code: Cookies.get('id_token'),
          });
          await Client.unsubAll();
        }
      })
      .catch((err) => {
        Log.error('An error occurred while trying to logout', err);
      })
      .finally(() => {
        Log.color('Logging out user and clearing cookies', 'orange');
        clearCookies();
        localStorage.clear();
        localStorage.setItem('logout', Date.now());
        window.location = '/';
      });
  }
}

export default new Logout();
