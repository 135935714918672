import { DELETE_MESSAGE, SET_MESSAGE } from '../constants/action-types';

export default function handleMessage(state = [], action) {
  let messages = state.slice();

  switch (action.type) {
    case SET_MESSAGE:
      messages.unshift(action.payload);

      return messages;
    case DELETE_MESSAGE:
      messages = [];

      return messages;
    default:
      return state;
  }
}
