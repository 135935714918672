import { SET_MP_PAUSE } from '../constants/action-types';
import Cookies from 'js-cookie';
import { STREAM_PAUSE } from '../components/Pages/Incoming/constants';

export default function setPauseStream(
  state = Cookies.get(STREAM_PAUSE) === 'true',
  action
) {
  switch (action.type) {
    case SET_MP_PAUSE:
      return action.payload;
    default:
      return state;
  }
}
