import Cookies from 'js-cookie';
import { SET_MP_DETECTION_FILTER } from '../constants/action-types';
import { REKOGNITION } from '../components/Pages/Incoming/constants';

export default function toggleRekognition(
  state = Cookies.get(REKOGNITION) === 'true',
  action
) {
  switch (action.type) {
    case SET_MP_DETECTION_FILTER:
      return action.payload;
    default:
      return state;
  }
}
