import Axios from 'axios';
import Cookies from 'js-cookie';
import CustomEvent from 'custom-event';
import { clearCookies } from '../utils/cookie';
import getHash from '../utils/getHash';
import Log from '../utils/Log';

export const getStateModel = (module, myState) => {
  try {
    return JSON.parse(localStorage.getItem(`current_app_model`)).model.modules[
      module
    ].states[myState];
  } catch {
    return null;
  }
};

export const getModuleModel = (module, dashboard) => {
  try {
    if (!dashboard) {
      return JSON.parse(localStorage.getItem(`current_app_model`)).model
        .modules[module];
    } else {
      return JSON.parse(localStorage.getItem(`current_app_model`)).model
        .modules[module].states[dashboard];
    }
  } catch {
    return null;
  }
};

export const getUserModel = () => {
  try {
    return JSON.parse(localStorage.getItem(`current_app_model`)).model.user
      .props;
  } catch (err) {
    return {};
  }
};

export const getUserConfigItem = (itemName, defaultObj) => {
  let ret = defaultObj === null ? {} : defaultObj;
  if (getUserModel().config) {
    const userConfig = JSON.parse(getUserModel().config);
    if (userConfig.hasOwnProperty(itemName)) {
      ret = userConfig[itemName];
    }
  }
  return ret;
};

export const updateUserConfigItem = (itemName, obj) => {
  let userObject = JSON.parse(localStorage.getItem(`current_app_model`));
  if (!userObject.model.user.props.config) {
    userObject.model.user.props.config = '{}';
  }
  let config = JSON.parse(userObject.model.user.props.config);
  config[itemName] = obj;
  userObject.model.user.props.config = JSON.stringify(config);
  localStorage.setItem(`current_app_model`, JSON.stringify(userObject));
  let event = new CustomEvent('configUpdated');
  document.dispatchEvent(event);
  let payload = { action: 'updateUserConfig', config: JSON.stringify(config) };
  Axios.post('/users', payload)
    .then((res) => {
      Log.color('Updated user config Result = ', res, 'Lawngreen');
    })
    .catch((err) => {
      Log.error(err);
    });
};

export const getUiModel = () => {
  const objModel = JSON.parse(localStorage.getItem(`current_app_model`)).model;
  // Log.color('the object model', objModel, '#5b0463');
  const { ui } = objModel;
  let newItems = [];
  let workSpaces = [];
  ui.leftNav.items.map((obj) => {
    if (obj.special) {
      if (obj.special === 'workspaces') {
        // Log.trace('ADD WORKSPACES HERE!');
        for (let key in objModel.modules) {
          const { workspace } = objModel.modules[key];
          if (workspace) {
            if (workspace.type === 'dashboard') {
              workSpaces.push({
                label: workspace.title,
                url: `/dashboard/`,
                workspace: key,
              });
            } else {
              if (workspace.tabs) {
                workspace.tabs.map((tab) => {
                  workSpaces.push({
                    label: tab.title || workspace.title,
                    url: `/workspace/${key}/${tab.view}#${getHash({
                      filter: workspace.views.find((e) => e.id === tab.view)
                        .filter,
                      sort: workspace.views.find((e) => e.id === tab.view).sort,
                    })}`,
                    workspace: `${key}.${tab.view}`,
                  });
                });
              } else {
                if (workspace && workspace.title) {
                  workSpaces.push({
                    label: workspace.title,
                    url: `/workspace/${key}`,
                    workspace: key,
                  });
                }
              }
            }
          }
        }
        newItems = newItems.concat(
          workSpaces.sort((a, b) => {
            if (a.label > b.label) {
              return 1;
            } else {
              return -1;
            }
          })
        );
      }
    } else {
      newItems.push(obj);
    }
  });
  ui.leftNav.items = newItems;
  // Log.trace('API UI -------------------------', ui);
  return ui.leftNav;
};

export const getMonitorModel = () => {
  return JSON.parse(localStorage.getItem(`current_app_model`)).model.ui.monitor;
};

export const loadModel = (token) => {
  return new Promise((resolve, reject) => {
    let success = false;
    if (token) {
      return Axios.get('/model').then((res) => {
        if (res.data && res.data.model) {
          // Log.trace('We loaded the model = ', res.data);
          localStorage.setItem(`current_app_model`, JSON.stringify(res.data));
          success = true;
        } else {
          Log.trace(
            'The model returned but there is no model object.  It looks like this:',
            res
          );
          success = false;
          localStorage.clear();
        }
        resolve(success);
      });
      // .catch(err => {
      //   Log.error('An error occurred while retrieving the model', err);
      //   clearCookies();
      //   localStorage.clear();
      //   window.location = '/';
      //   reject(success);
      // });
    } else {
      Log.warn('No token available', token, Cookies.get('active_session'));
      localStorage.clear();
      clearCookies();
      reject(success);
    }
  });
};
