import { SET_MAP_ON_IMAGE_VIEW } from '../constants/action-types';

export default function (state = {}, action) {
  switch (action.type) {
    case SET_MAP_ON_IMAGE_VIEW:
      const newState = { ...state, ...action.payload };
      return newState;
    default:
      return state;
  }
}
