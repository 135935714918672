import { SET_MP_PICTURE_INSTRUCTIONS } from '../constants/action-types';

export default function setMonitoringPageInstructions(state = {}, action) {
  switch (action.type) {
    case SET_MP_PICTURE_INSTRUCTIONS:
      return action.payload;
    default:
      return state;
  }
}
