import { TOGGLE_CHATSTREAM } from '../constants/action-types';
import { getUserConfigItem } from '../models';
import { SHOW_CHATSTREAM } from '../constants/userConfig';

export default function toggleChatStream(
  state = getUserConfigItem(SHOW_CHATSTREAM, false),
  action
) {
  switch (action.type) {
    case TOGGLE_CHATSTREAM:
      return action.payload;
    default:
      return state;
  }
}
