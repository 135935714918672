import { SET_FOCUSED_IMAGE } from '../constants/action-types';

export default function (state = {}, action) {
  switch (action.type) {
    case SET_FOCUSED_IMAGE:
      const newState = { ...state, ...action.payload };
      return newState;
    default:
      return state;
  }
}
