/* eslint-disable react/display-name */
import React, { Component, lazy, Suspense } from 'react';
import Axios from 'axios';
import Cookies from 'js-cookie';
import { Spinner } from 'reactstrap';
import history from './history';
import {
  clearCookies,
  clearIntervals,
  keepSessionAlive,
  manageSession,
  refreshToken,
  setRefreshErrorCookie,
} from '../utils/cookie';
import Log from '../utils/Log';
import Timeout from '../utils/Timeout';
import Client from '../utils/client';
import { getUserModel, loadModel, getModuleModel } from '../models';
import getUrlVars from '../utils/getUrlVars';

const Growl = lazy(() => import('../containers/Growl'));
const TimeoutBreach = lazy(() => import('../containers/Auth/TimeoutBreach'));
const Router = lazy(() => import('../router'));
const DispatchPrompt = lazy(() => import('../containers/Auth/DispatchPrompt'));
const DuplicateSession = lazy(() =>
  import('../containers/Auth/DuplicateSession')
);
const SignIn = lazy(() => import('../containers/Auth/SignIn'));

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticatedUser: false,
      modelLoaded: false,
      isDuplicate: false,
      allChecked: false,
    };
  }

  componentDidMount() {
    Log.trace('mounting app component');
    Timeout.update();
    setRefreshErrorCookie(0);
    this.manageToken().then(async (token) => {
      Log.color('WE ARE READY', 'orange');
      Log.color('my token', token, 'orange');

      await this.configureAxios();
      Log.color('finished configuring axios', 'orange');

      let activeSession = Cookies.get('session');
      Log.color('active SEssion', activeSession, 'orange');

      this.setState({
        authenticatedUser: token && activeSession,
      });

      Log.color('finished setting authenticated user', this.state, 'orange');

      if (token && !this.state.isDuplicate) {
        Log.color('What is my history like here?', history, 'orange');
        this.getModel(token);
      }
    });
  }

  componentWillUnmount() {
    clearIntervals();
  }

  getModel = (token) => {
    Log.color('Loading model and state = ', this.state, 'orange');
    loadModel(token).then((success) => {
      if (success) {
        Log.color('Model loaded changing state = ', this.state, 'orange');
        Client.subscribe(
          `sessions/${getUserModel().email.toLowerCase()}`,
          this.processSession
        );

        let dispatchSwitch = false;
        let timeoutMod = getModuleModel('Timeout');

        if (!localStorage.getItem('dispatch')) {
          Log.color(
            '################################Check to see if we are allowed to dispatch... module model = ',
            timeoutMod,
            'Fuchsia'
          );
          if (timeoutMod && timeoutMod.states.Override) {
            if (Cookies.get('dispatch') !== 'true') {
              dispatchSwitch = true;
            } else {
              localStorage.setItem('dispatch', 'yes');
              dispatchSwitch = false;
            }
          }
        }

        this.setState(
          {
            isDuplicate: false,
            modelLoaded: true,
            showDispatch: dispatchSwitch,
          },
          () => {
            Log.color('Changed Model loaded state ', this.state, 'orange');

            if (!dispatchSwitch) {
              this.sendReturn();
            }
          }
        );
      } else {
        Log.color(
          'Model was not successfully loaded or does not exist',
          'id_token:',
          Cookies.get('id_token'),
          'active_session:',
          Cookies.get('session'),
          'orange'
        );
      }
    });
  };

  sendReturn = () => {
    let model = JSON.parse(localStorage.getItem('current_app_model'));

    if (model.model.ui.forcePage) {
      history.push(model.model.ui.forcePage);
      localStorage.removeItem('returnLocation');
    } else if (localStorage.getItem('returnLocation')) {
      if (localStorage.getItem('returnLocation') !== '/') {
        history.push(localStorage.getItem('returnLocation'));
      }
      localStorage.removeItem('returnLocation');
    }
  };

  processSession = (msg) => {
    Log.color(
      'Processing a session message to see if logging out. msg = ',
      msg,
      `id token = ${Cookies.get('id_token')}`,
      'orange'
    );
    if (
      msg.action === 'logout' &&
      (!Cookies.get('id_token') || msg.code === Cookies.get('id_token'))
    ) {
      Log.color('------------------- logging out', msg, 'orange');
      this.logout();
    }
  };

  configureAxios = () => {
    return new Promise((resolve, reject) => {
      Log.trace('Configuring axios');
      Axios.defaults.baseURL = `https://${process.env.API_URL}`;
      Axios.defaults.headers.post['Content-Type'] = 'application/json';
      Axios.defaults.headers.get['Cache-Control'] = 'no-cache';
      Axios.defaults.headers.get['Pragma'] = 'no-cache';
      Axios.interceptors.request.use((config) => {
        // Log.color('axios config = ', config, 'moccasin');
        if (!config.url.includes('saml/refreshSession')) {
          Timeout.update();
        }
        const token = Cookies.get('id_token');
        if (token) {
          config.headers.Authorization = token;
        } else {
          config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        return config;
      }),
        (err) => {
          Log.error('Error inside configure Axios', err);
          reject(err);
        };
      Axios.interceptors.response.use(null, async (err) => {
        Log.color('intercepting request', err, 'red');
        if (err.config && err.response) {
          if (err.response.status === 401 || err.response.status === 403) {
            Log.error(
              'refreshing token inside axios interceptor because req was unauthorized',
              err
            );
            await refreshToken();
            return Axios.request(err.config);
          }
          if (err.response.status === 502) {
            Log.error('A 502 was returned', err);
          }
        }
        return err;
      });
      resolve();
    });
  };

  logout = () => {
    try {
      Log.color('CLEANING OUT STORAGE STUFF from app.js logout!', 'orange');
      Client.publish(`sessions/${getUserModel().email.toLowerCase()}`, {
        action: 'logout',
        code: Cookies.get('id_token'),
      });
    } catch (ex) {
      Log.color('Something was missing yo');
    } finally {
      clearCookies();
      localStorage.clear();
      window.location = '/';
    }
  };

  manageToken = () => {
    const props = getUrlVars();
    const code = props.code;

    return new Promise(async (resolve, reject) => {
      try {
        let token;
        let dupSession = false;
        let startInterval = manageSession(code);

        if (startInterval) {
          Log.color('begin keeping session alive', 'orange');
          keepSessionAlive();
        }
        if (props.status === 'D') {
          // if another session is detected
          Log.color('This is a duplicate session!', 'orange');
          dupSession = true;
        }
        token = code ? code : Cookies.get('id_token');

        if (!code && Cookies.get('id_token')) {
          // refresh the token on page refresh/load, not on sign in
          // and the id token expires
          Log.color('refreshing token on page load/refresh', 'orange');
          await refreshToken();
        }
        this.setState(
          {
            isDuplicate: dupSession,
            allChecked: true,
          },
          () => {
            Log.color('token before resolve', token, 'orange');
            resolve(token);
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  };

  dispatchClicked = () => {
    this.setState({ showDispatch: false }, () => {
      this.sendReturn();
    });
  };

  render() {
    const {
      authenticatedUser,
      modelLoaded,
      isDuplicate,
      allChecked,
      showDispatch,
    } = this.state;
    if (allChecked || authenticatedUser) {
      if (authenticatedUser) {
        if (!isDuplicate) {
          if (modelLoaded) {
            if (!showDispatch) {
              Log.trace(
                'RENDERING APPP ======================================'
              );
              return (
                <div id="app">
                  <Suspense fallback={<Spinner>Loading...</Spinner>}>
                    <Growl />
                    <TimeoutBreach />
                    <Router history={history} />
                  </Suspense>
                </div>
              );
            }
            return (
              <Suspense fallback={<Spinner>Loading...</Spinner>}>
                <DispatchPrompt dispatchClicked={this.dispatchClicked} />
              </Suspense>
            );
          }
          return <Spinner>Loading...</Spinner>;
        }
        return (
          <Suspense fallback={<Spinner>Loading...</Spinner>}>
            <DuplicateSession getModel={this.getModel} logout={this.logout} />
          </Suspense>
        );
      }
      return (
        <Suspense fallback={<Spinner>Loading...</Spinner>}>
          <SignIn />
        </Suspense>
      );
    }
    return <Spinner>Loading...</Spinner>;
  }
}

export default (props) => {
  return <App {...props} />;
};
