import { SET_CHATSTREAM, UPDATE_CHATSTREAM } from '../constants/action-types';

export default function chatStream(state = [], action) {
  let chats = state.slice();

  switch (action.type) {
    case SET_CHATSTREAM:
      return action.payload;

    case UPDATE_CHATSTREAM:
      chats.unshift(action.payload);
      chats.pop();

      return chats;
    default:
      return state;
  }
}
