// import Log from './Log';

const getHash = (obj) => {
  // Log.color('setting hash with object', obj, 'navy');
  if (!obj) return '';

  let newHash = '';
  if (obj.filter && obj.filter.length > 0) {
    // Log.color('Setting the hash for real filter', obj.filter, 'navy');
    newHash += JSON.stringify(obj.filter);

    if (newHash !== '') {
      newHash = `ff=${encodeURIComponent(newHash)}`;
    }
  }

  if (obj.sort && obj.sort.length > 0) {
    // Log.color('Setting the hash for sort', obj.sort, 'dodgerblue');

    if (newHash !== '') {
      newHash += '&';
    }

    newHash += `fs=${encodeURIComponent(JSON.stringify(obj.sort))}`;
  }

  return newHash;
};

export default getHash;
