import { CREATE_CHAT_ALERT, DELETE_CHAT_ALERT } from '../constants/action-types';

export default function chatAlerts(state = [], action) {
  let alerts = state.slice();

  switch (action.type) {
    case CREATE_CHAT_ALERT:
      alerts.unshift(action.payload);

      return alerts;

    case DELETE_CHAT_ALERT:
      alerts = alerts.filter(el => el.uuid !== action.payload);

      return alerts;

    default:
      return state;
  }
}
