import { SET_MP_BACKLOG } from '../constants/action-types';

export default function setBacklogCount(state = 0, action) {
  switch (action.type) {
    case SET_MP_BACKLOG:
      return action.payload;
    default:
      return state;
  }
}
