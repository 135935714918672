// import '../wdyr';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'bootstrap';
import App from './components/App';
import configureStore from './store';
import './assets/bootstrap.scss';
import './assets/bootstrap_overrides.css';
import './index.scss';
import 'react-tooltip/dist/react-tooltip.css';

const store = configureStore();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
