/* eslint-disable no-console */
import moment from 'moment';
// import Log from './Log';

class Timeout {
  update() {
    const newDateObj = moment(moment()).add(process.env.TIMEOUT, 'minutes');
    // Log.color(`Set time to logout...${newDateObj.format()}`, 'Fuchsia');
    localStorage.setItem('timeout', newDateObj.format());
  }
}

export default new Timeout();
