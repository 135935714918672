import { TOGGLE_FLAG_ALERTS } from '../constants/action-types';

export default function toggleFlagAlert(state = true, action) {
  switch (action.type) {
    case TOGGLE_FLAG_ALERTS:
      return action.payload;
    default:
      return state;
  }
}
