import Axios from 'axios';
import awsIot from 'aws-iot-device-sdk';
import Cookies from 'js-cookie';
import { getUserModel } from '../models';
import Log from './Log';
import Timeout from './Timeout';
import {
  IOT_ACCESS_KEY,
  IOT_SECRET_KEY,
  IOT_SESSION_TOKEN,
} from '../constants/cookies';

const match = require('mqtt-match');

const twelveHrs = 0.5;

const Client = {
  myClient: null,
  status: 'noCreds',
  isUserActive: false,
  getIOTCreds() {
    return new Promise((resolve, reject) => {
      this.isUserActive = getUserModel().status === 'A';

      if (
        (Cookies.get(IOT_ACCESS_KEY) && Cookies.get('active_session')) ||
        !this.isUserActive
      ) {
        // Log.color(
        //   `Dont need em my status = ${getUserModel().status} active = ${
        //     this.isUserActive
        //   } ${getUserModel().status === 'A'} `,
        //   'navy'
        // );
        resolve();
      } else {
        // Log.color('getting iot creds', 'navy');

        Axios.post('/iot', { action: 'getIOT' })
          .then((res) => {
            // Log.color('********************Got iot creds', res, 'blue');
            const { accessKey, secretKey, sessionToken } = res.data;
            Cookies.set(IOT_ACCESS_KEY, accessKey, {
              expires: twelveHrs,
              sameSite: 'strict',
              secure: false,
            });
            Cookies.set(IOT_SECRET_KEY, secretKey, {
              expires: twelveHrs,
              sameSite: 'strict',
              secure: false,
            });
            Cookies.set(IOT_SESSION_TOKEN, sessionToken, {
              expires: twelveHrs,
              sameSite: 'strict',
              secure: false,
            });
            resolve();
          })
          .catch((err) => {
            Log.error('Error getting iot creds', err);
            reject(err);
          });
      }
    });
  },
  async makeClient() {
    if (this.status === 'noCreds') {
      this.status = 'gettingCreds';
      // Log.color('I need to get the creds...', 'navy');
      await this.getIOTCreds();
      const {
        id: userId,
        fname,
        lname,
        sector_id,
        station_id,
      } = getUserModel();
      // Log.color('I got the creds...', 'navy');
      const id = `${
        process.env.ENV
      }-${fname}-${lname}-${userId}-${sector_id}-${station_id}-${Date.now()}`;
      Log.trace(`Creating AWS IOT Client: ${id}`);
      const clientObj = {
        clientId: id,
        region: process.env.AWS_REGION,
        protocol: 'wss',
        accessKeyId: Cookies.get(IOT_ACCESS_KEY),
        secretKey: Cookies.get(IOT_SECRET_KEY),
        sessionToken: Cookies.get(IOT_SESSION_TOKEN),
        port: 443,
        host: process.env.PUB_SUB_ENDPOINT,
        autoResubscribe: true,
        offlineQueueing: true,
        // debug: true,
      };
      // Log.color('Creating AWS Iot client', clientObj, 'purple');
      this.myClient = awsIot.device(clientObj);
      this.myClient.id = id;
      this.myClient.mySubscriptions = [];
      this.myClient.mySubscriptionsCBLookup = [];
      this.myClient.unsubAll = this.unsubAll;
      this.myClient.unsub = this.unsub;
      this.myClient.on('message', this.onMessage);
      this.myClient.on('close', this.onClose);
      this.myClient.on('error', this.onError);
      this.myClient.on('connect', () => {
        Log.trace(`Client: ${this.myClient.id} is connected`);
      });
      this.status = 'haveCreds';
    } else {
      if (this.status !== 'haveCreds') {
        // Log.color(
        //   'I do not already have creds so I need to wait on the one that is getting them.',
        //   'navy'
        // );
        await this.checkCreds();
        // Log.color('Got back from checkCreds...', 'navy');
      }
      // else {
      //   Log.color('Someone already got the creds so I will use them', 'navy');
      // }
      // Log.trace('Got a client', this.myClient);
    }
  },
  checkCreds() {
    return new Promise(async (resolve, reject) => {
      // Log.color('Checking creds and my status = ', this.status, 'navy');
      let i = 0;
      while (this.status !== 'haveCreds' && i < 20) {
        i++;
        // Log.color('Still Waiting my status = ', this.status, 'navy');
        await this.delay(500);
      }
      resolve();
    });
  },
  delay(milliseconds) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve(true);
      }, milliseconds);
    });
  },
  onError(err) {
    Log.error('An Error occurred with client', err);
  },
  unsubAll() {
    // Log.color('Unsubscribing and myClient = ', this.myClient, 'navy');
    try {
      this.myClient.unsubscribe(this.subscriptions);
      Log.trace('Successfully unsubscribed from ', this.myClient);
      this.mySubscriptions = [];
      this.mySubscriptionsCBLookup = [];
    } catch (err) {
      // Log.error('Error trying to unsubscribe from myClient = ', this.myClient);
    }
  },
  unsub() {
    // Log.trace(`Unsubscribing from ${this.topic}`);
    this.myClient.unsubscribe(this.topic);
    this.myClient.mySubscriptions = this.myClient.mySubscriptions.filter(
      (e) => e !== this.topic
    );
    this.myClient.mySubscriptionsCBLookup =
      this.myClient.mySubscriptionsCBLookup.filter(
        (e) => e.topic != this.topic
      );
    // Log.color('My client = ', this.myClient, 'green');
  },

  onClose() {
    // no params and anything useful can be provided here
    Log.trace('Iot connection closed and may be reconnecting');
  },

  onMessage(topic, msg) {
    //This = myClient in this function
    let str = String.fromCharCode.apply(null, msg);
    let decode = JSON.parse(str);
    // Log.color('received a msg on topic:', topic, 'msg=', decode, 'navy');
    // Log.color('my subs=', this.mySubscriptionsCBLookup, 'navy');
    this.mySubscriptionsCBLookup.map((el) => {
      if (match(el.topic, topic)) {
        // Log.color('Found it! running the CB in the el = ', el, 'navy');
        el.cb(decode);
      }
    });
  },

  subscribe(topic, cb) {
    return new Promise(async (resolve, reject) => {
      try {
        // Log.color('subscribing with topic=', topic, 'cb=', cb, 'navy');
        const envTopic = `${process.env.ENV}/${topic}`;
        await this.makeClient();
        // Log.color('My this inside subscribe', this, 'navy');
        const alreadySubscribed = this.myClient.mySubscriptions.find((el) => {
          return el === envTopic;
        });
        let objRet = {
          topic: envTopic,
          cb: cb,
          unsub: this.unsub,
          myClient: this.myClient,
        };

        if (!alreadySubscribed) {
          // Log.color('I AM TRYING TO SUBSCRIBE!', envTopic, 'what is this?', this, 'navy');
          if (getUserModel().status !== 'A') {
            // Log.color('I am actually inactive', 'yellow');
            return;
          }
          try {
            // Log.color('subscribing to topic:', envTopic, 'navy');

            this.myClient.mySubscriptions.push(envTopic);
            this.myClient.subscribe(this.myClient.mySubscriptions);
            this.myClient.mySubscriptionsCBLookup.push({
              topic: envTopic,
              cb: cb,
            });
            // Log.trace(`Success! Subscribed to: ${envTopic}`);
            // Log.color(`my objRet: ${objRet.topic}`, 'navy');
          } catch (err) {
            // if connection is closed, create new client
            Log.error(`Error subscribing to ${envTopic}`);
            Log.trace('Trying with new client');
            this.status = 'noCreds';
            // only want to try once
            await this.makeClient();
            this.myClient.mySubscriptions.push(envTopic);
            this.myClient.subscribe(this.myClient.mySubscriptions);
            this.myClient.mySubscriptionsCBLookup.push({
              topic: envTopic,
              cb: cb,
            });
            Log.color('Retried subscription successful', 'navy');
          }
        }
        resolve(objRet);
      } catch (err) {
        reject(err);
      }
    });
  },

  async publish(topic, message) {
    if (!topic.includes('heartBeat')) {
      Timeout.update();
    }
    const envTopic = `${process.env.ENV}/${topic}`;
    // Log.color('Publishing a message', message, ' on the topic ', envTopic, 'navy');
    // Log.color('My this inside publish', this, 'LightSeaGreen');
    await this.makeClient();

    try {
      this.myClient.publish(envTopic, JSON.stringify(message));
    } catch (err) {
      // if connection is closed, create new client
      Log.error(`Error publishing to ${envTopic}`);
      Log.color('Recreating new client and trying publish...');
      this.status = 'noCreds';
      // could recurse here but then it could retry infinitely
      await this.makeClient();
      this.myClient.publish(envTopic, JSON.stringify(message));
    }
  },
};

export default Client;
