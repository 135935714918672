import { combineReducers } from 'redux';
import chatAlerts from './chatAlerts';
import chatStream from './chatStream';
import viewAllChats from './viewAllChats';
import showChatStream from './toggleChatStream';
import chatInput from './chatInput';
import lastCreatedChat from './lastCreatedChat';
import messages from './messages';
import showMapOnImageView from './showMapOnImageView';
import focusedImageOnImageView from './setFocusedImageOnImageView';
import backlogCount from './backlog';
import isStreamPaused from './pauseStream';
import pictureInstructions from './pictureInstructions';
import isRekognitionOn from './toggleRekognition';
import isSubscribedToFlags from './flagAlert';
// the name you import here = the prop name on the store

const rootReducer = combineReducers({
  chatAlerts,
  chatStream,
  viewAllChats,
  showChatStream,
  chatInput,
  lastCreatedChat,
  messages,
  showMapOnImageView,
  focusedImageOnImageView,
  backlogCount,
  isStreamPaused,
  pictureInstructions,
  isRekognitionOn,
  isSubscribedToFlags,
});

export default rootReducer;
